import { Memoize } from 'typescript-memoize'

import type { AuthService } from '../services/auth.service'
class ServiceProvider {
  @Memoize()
  get auth() {
    const service: typeof AuthService =
      require('../services/auth.service').AuthService
    return new service()
  }
}

export const serviceProvider = new ServiceProvider()
