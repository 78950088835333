import { Memoize } from 'typescript-memoize'

import { firebaseProvider } from './firebase.provider'
import * as prodConfig from './production.config'
import { serviceProvider } from './service.provider'
import * as stagingConfig from './staging.config'

class Provider {
  @Memoize()
  get service() {
    return serviceProvider
  }

  @Memoize()
  get firebase() {
    return firebaseProvider
  }

  @Memoize()
  get environment() {
    return require('./env.config').default
  }

  @Memoize()
  get config() {
    return this.environment === 'production' ? prodConfig : stagingConfig
  }
}

export const provider = new Provider()
